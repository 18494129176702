<template>
  <dashboard-content>
    <patient-form :client="client" />
  </dashboard-content>
</template>

<script>
import defaultState from '@/store/clients/state'
import DashboardContent from '@/components/dashboard/DashboardContent'
import PatientForm from '@/views/dashboard/patient/PatientForm'

export default {
  name: 'PatientAdd',
  components: { DashboardContent, PatientForm },
  data() {
    return {
      client: { ...defaultState().client, channel: 'sms' }
    }
  }
}
</script>
